import {Col, Row, Tab, Tabs} from "react-bootstrap";
import ProductCard from "./ProductCard";

function ProductsByCategory(props) {
    const products = props.products ? props.products : [];
    const categories = props.categories ? props.categories : [];

    if (categories.length === 0) {
        return (
            products.map((product, key) => {
                return (
                    <Col sm={"12"} id={key} md={"6"} lg={"4"} xl={"4"} xxl={"3"} key={key}>
                        <ProductCard
                            {...props}
                            product={product}
                        />
                    </Col>
                )
            })
        );
    }
    return (
        <Tabs justify  className="mb-3">
            {categories.map((category, key) => {
                if (category.products && category.products.length > 0) {
                    return (
                        <Tab title={category.name} eventKey={category.id} key={key}>
                            <h4>Productos de {category.name}</h4>
                            <small className={"text-muted"}>{category.description}</small>
                            <Row className="mt-3">
                                {category.products?.map((product, key) => {
                                    return (
                                        <Col sm={"12"} id={key} md={"6"} lg={"4"} xl={"4"} xxl={"3"} key={key}>
                                            <ProductCard
                                                {...props}
                                                product={product}
                                            />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Tab>
                    )
                }
                return null
            })}
        </Tabs>
    )


}

export default ProductsByCategory;
