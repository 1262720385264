export const saveToken = (token) => {
    return {
        type: 'SET_TOKEN',
        token: token
    }
}

export const clearToken = () => {
    return {
        type: 'CLEAR_TOKEN'
    }
}

export const saveRefreshToken = (refresh) => {
    return {
        type: 'SET_REFRESH_TOKEN',
        refresh: refresh
    }
}

export const clearRefreshToken = () => {
    return {
        type: 'CLEAR_REFRESH_TOKEN'
    }
}

export const setUser = (user) => {
    return {
        type: 'LOGGED_IN',
        user
    }
}
export const clearUser = () => {
    return {
        type: 'SIGN_OUT'
    }
}

export const setOrder = (order) => {
    return {
        type: 'SET_ORDER',
        order
    }
}
export const clearOrder = () => {
    return {
        type: 'CLEAR_ORDER'
    }
}

export const setProducts = (products) => {
    return {
        type: 'SET_PRODUCTS',
        products
    }
}
export const clearProducts = () => {
    return {
        type: 'CLEAR_PRODUCTS'
    }
}

export const setClient = (client) => {
    return {
        type: 'SET_CLIENT',
        client
    }
}
export const clearClient = () => {
    return {
        type: 'CLEAR_CLIENT'
    }
}
