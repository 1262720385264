import {Component} from "react";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import ProductCardSimple from "../Components/ProductCardSimple";

class CarOrder extends Component {
    render() {
        let order = this.props.order;
        let total = 0;
        if (order && order.products && order.products.length > 0) {
            let totals = order.products.map((product) => {
                return product.total
            })
            for (const k of totals) {
                total = total + k
            }
        }
        return (
            <div>
                <h2>Mi Carrito</h2>
                <hr/>
                {order.products && order.products.length > 0 ? (
                    <div>
                        <Row className={"text-end"}>
                            <NavLink to={"/store/home"}>
                                Seguir viendo el catalogo
                            </NavLink>
                        </Row>
                        {
                            order.products.map((product, key) => {
                                return (
                                    <ProductCardSimple
                                        {...this.props}
                                        product={product}
                                    />
                                )
                            })
                        }
                        <Row className={"text-end"}>
                            <NavLink to={"/store/home"}>
                                Agregar mas productos
                            </NavLink>
                        </Row>
                        <Alert variant={"info"}>
                            <Row style={{textAlign: 'right'}}>
                                <Col>
                                    <b>Total Orden</b>
                                </Col>
                                <Col>
                                    <b>${new Intl.NumberFormat('de-DE').format(total)}</b>
                                </Col>
                            </Row>
                        </Alert>
                        <Row>
                            <div className={"d-none d-lg-block"}>
                                <Row>
                                    <NavLink to={"/store/checkout"} className={"text-decoration-none btn btn-lg"}
                                             style={{backgroundColor: '#c0ce00', borderColor: '#c0ce00', color: "black"}}
                                    >
                                        Confirmar Pedido
                                    </NavLink>
                                </Row>
                            </div>
                            <div className={"position-fixed bottom-0 d-block d-lg-none start-0"}>
                                <Row>
                                    <NavLink to={"/store/checkout"} className={"text-decoration-none btn btn-lg"}
                                             style={{backgroundColor: '#c0ce00', borderColor: '#c0ce00', color: "black"}}
                                    >
                                        Confirmar Pedido
                                    </NavLink>
                                </Row>
                            </div>
                        </Row>
                    </div>
                ) : (
                    <div>
                        <Alert variant={"danger"}>
                            El carrito esta vacio
                            <hr/>
                            <NavLink to={"/store/home"}>
                                <Button variant={"primary"}>
                                    Ver nuestro catalogo de productos
                                </Button>
                            </NavLink>
                        </Alert>
                    </div>
                )}
            </div>
        )
    }
}

export default CarOrder;