import {Component} from "react";
import {Badge, Col, Row, Spinner} from "react-bootstrap";
import ProductCard from "../Components/ProductCard";
import {NavLink} from "react-router-dom";
import {FiShoppingCart} from "react-icons/fi";

class SearchResults extends Component{
    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search)
        if(!this.props.searchText && params.get("q")){
            const params = new URLSearchParams(this.props.location.search)
            this.props.setSearchText(params.get("q"));
        }
    }

    render() {
        return(
            <div>
                <h2>Resultado de la busqueda "<b>{this.props.searchText}</b>": </h2>
                <hr/>
                <Row>
                    {this.props.readySearch ? (
                        this.props.productsSearched.map( (product, key) => {
                            return (
                                <Col sm={"12"} id={key} md={"6"} lg={"4"} xl={"4"} xxl={"3"} key={key}>
                                    <ProductCard
                                        {...this.props}
                                        product={product}
                                    />
                                </Col>
                            );
                        })
                    ):(
                        <div className={"text-center"}>
                            <Spinner animation={"border"} variant={"info"}></Spinner>
                        </div>
                    )}
                </Row>
                <Row>
                    {this.props.order && this.props.order.products && this.props.order.products.length > 0 ? (
                        <div className={"position-fixed bottom-0 d-block d-lg-none start-0"}>
                            <Row>
                                <NavLink to={"/store/order"} className={"text-decoration-none btn btn-secondary btn-lg"}>
                                    <i><FiShoppingCart/></i> Ver Carrito <Badge bg="warning">{this.props.order.products.length}</Badge>
                                </NavLink>
                            </Row>
                        </div>
                    ): null}
                </Row>

            </div>
        )
    }
}
export default SearchResults;