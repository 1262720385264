import {NavLink, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Alert, Col, Row, Spinner, Table} from "react-bootstrap";
const loadDate = (datum) => {
    let date = new Date(datum * 1000).toLocaleDateString("es-US")
    let hour = new Date(datum * 1000).toLocaleTimeString("es-US")
    return date + " " + hour;
}

function getStatus(status) {
    if(status==="active"){
        return "ABIERTA"
    }
    if (status==="process"){
        return "ALISTANDO"
    }
    if (status==="closed"){
        return "ENTREGADA"
    }
    return "N/A";
}

function Order(props) {
    let {orderId} = useParams();
    let [order, setOrder] = useState(null);
    let [error, setError] = useState(null);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.client && props.client.id && !order) {
            props.loadGet("/orders/" + props.client.id+"/"+orderId,
                (order) => {
                    setOrder(order);
                    setLoading(false);
                },
                (error) => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, [order, orderId, props]);

    if(loading){
        return (
            <div className={"text-center"}>
                <br/>
                <Spinner animation={"border"} />
            </div>
        )
    }

    if(error){
        return (
            <div>
                <NavLink to={"/store/orders"}>
                    Mis ordenes
                </NavLink>
                <h2>Orden N. {orderId}</h2>
                <hr/>
                <Alert variant={"danger"}>
                    Algo salio mal:
                    <hr/>
                    {error}
                </Alert>
            </div>
        )
    }
    let total = 0;
    return (
        <div>
            <NavLink to={"/store/orders"}>
                Mis ordenes
            </NavLink>
            <h2 className={"text-center"}>Orden N. {orderId}</h2>
            <hr/>
            <Alert variant={"info"}>
                <Table size={"sm"} hover>
                    <tr>
                        <th>Cliente:</th>
                        <td>{order?.client?.name}</td>
                        <th>Nit/CC:</th>
                        <td>{order?.client?.identification}</td>
                    </tr>
                    <tr>
                        <th>Estab:</th>
                        <td>{order?.client?.establishment_name}</td>
                        <th>Ciudad:</th>
                        <td>{order?.client?.city}/{order?.client?.department}</td>
                    </tr>
                </Table>
            </Alert>
            <Alert>
               <small>Estado: <b>{getStatus(order.status)}</b></small>
                <custom className={"float-end"}>
                    {order?.advisory?(
                        <small>Asesor de su orden: <b>{order.advisory}</b></small>
                    ):(
                        <small>No tienes asesor asignado para esta orden</small>
                    )}
                </custom>
            </Alert>
            {order?.products?(
                <>
                    <h4>Productos:</h4>
                    <Table size={"sm"} hover striped bordered responsive>
                        <thead>
                        <tr className={"text-center"}>
                            <th>CODIGO</th>
                            <th>DESCRIPCIÓN</th>
                            <th>IVA</th>
                            <th>CANT</th>
                            <th>VALOR U</th>
                            <th>VALOR T</th>
                        </tr>
                        </thead>
                        <tbody>
                        {order?.products?.map( (product) => {
                            total+=product.total;
                            return(
                                <tr>
                                    <td className={"text-center"}>{product.code}</td>
                                    <td>{product.name}</td>
                                    <td className={"text-center"}>{product.iva}</td>
                                    <td className={"text-end"}>{new Intl.NumberFormat('de-DE').format(product.amount)}</td>
                                    <td className={"text-end"}>${new Intl.NumberFormat('de-DE').format(product.value)}</td>
                                    <td className={"text-end"}>${new Intl.NumberFormat('de-DE').format(product.total)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    <Alert className={"text-end"}>
                        <Row>
                            <Col>
                                <b>Total Orden</b>
                            </Col>
                            <Col>
                                <b>${new Intl.NumberFormat('de-DE').format(total)}</b>
                            </Col>
                        </Row>
                    </Alert>
                </>
            ):(
                <Alert variant={"danger"}>
                    No hay productos para esta orden
                </Alert>
            )}

            <Alert variant={"info"}>
                <small> Orden creada el {loadDate(order.date)}</small>
                <custom className={"float-end"}>
                    <small>{order?.from_store?("Orden creada por el cliente en Store"):("ADMIN LIDER CAMARGO")}</small>
                </custom>
            </Alert>

        </div>
    )
}

export default Order;