import React, {Component} from "react";
import './assets/css/App.css';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Portal from "./layouts/Portal";
import Login from "./layouts/Login";
import Logout from "./layouts/Logout";
window.Buffer = window.Buffer || require("buffer").Buffer;


class App extends Component{

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={"/store"} render={props => <Portal {...props}/>}/>
                    <Route path={"/login"} render={props => <Login {...props} />} />
                    <Route path={"/logout"} render={props => <Logout {...props}/>}/>
                    <Redirect to={"/store/home"}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
