import {Component} from "react";
import IndexNavbar from "../Components/IndexNavbar";
import MainPanel from "../Components/MainPanel";
import routes from "../routes";
import {Route} from "react-router-dom";
import Footer from "../Components/Footer";
import {
    clearClient,
    clearOrder,
    clearRefreshToken,
    clearToken,
    clearUser,
    saveRefreshToken,
    saveToken, setClient,
    setOrder,
    setUser
} from "../initializers/actions";
import {connect} from "react-redux";
import {Alert, Button, Card, Container} from "react-bootstrap";
import axios from "axios";
import properties from "../properties";

class Portal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            error: false,
            functionToken: null,
            order: this.props.order ? this.props.order : {},
            searchText: '',
            products: [],
            ready: false,
            client: this.props.client,
        }
    }

    componentDidMount() {
        if (this.props.user && this.props.user['custom:client_id'] && !this.props.client) {
            this.loadGet("/clients/" + (this.props.user && this.props.user['custom:client_id'] ? this.props.user['custom:client_id'] : "anonymous"),
                this.loadContentClients, this.loadError);
        }
    }

    loadContentClients = (client) => {
        if (client) {
            this.props.setClient(client);
            this.setState({
                client
            });
        }
    }

    render() {
        return (
            <div>
                <IndexNavbar
                    current={this.calculateNumberOfProducts()}
                    user={this.props.user}
                    setSearchTextParent={this.initSearchEvent}
                />
                {this.state.error ? (
                    <Container fluid>
                        <Card bg={"secondary"} text={"white"}>
                            <Card.Header>Error</Card.Header>
                            <Card.Body>
                                <Alert variant={"danger"}>
                                    <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                    <p>
                                        {this.state.error}
                                    </p>
                                    <hr/>
                                    <Button onClick={() => this.loadNewToken(this.state.functionToken)}
                                            variant={"light"}>
                                        Intentar nuevamente
                                    </Button>
                                </Alert>
                            </Card.Body>
                            <Card.Footer>
                            </Card.Footer>
                        </Card>
                    </Container>
                ) : (
                    <MainPanel route={this.getRoute(routes)}/>
                )}
                <Footer/>
            </div>
        )
        /*
        return (
            <Redirect
                to={"/logout"}
            />
        )
         */

    }

    getRoute = (routes) => {
        return routes.map((route, key) => {
            let params = route.params ? route.params : "";
            if (route.layout === "/store") {
                return (
                    <Route
                        path={route.layout + route.path + params}
                        key={key}
                        render={routeProps => (
                            <route.component
                                {...routeProps}
                                loadGet={this.loadGet}
                                loadPublicGet={this.loadPublicGet}
                                loadPost={this.loadPost}
                                loadPut={this.loadPut}
                                loadDelete={this.loadDelete}
                                loadGetBucket={this.loadGetBucket}
                                setOrder={this.setOrder}
                                order={this.state.order}
                                searchText={this.state.searchText}
                                setSearchText={this.initSearchEvent}
                                productsSearched={this.state.products}
                                readySearch={this.state.ready}
                                user={this.state.user}
                                client={this.state.client}
                            />
                        )}
                    />
                )
            } else {
                return null;
            }
        })
    }

    loadGet = (endpoint, loadFunction, errorFunction) => {
        axios({
            url: properties.api_host + endpoint,
            method: "GET",
            headers: {
                'Authorization': this.props.token
            }
        }).then((response) => {
            if (response.data) {
                try {
                    loadFunction(response.data);
                } catch (error) {
                    errorFunction("Application Error: " + error);
                }
            } else {
                errorFunction(JSON.stringify(response));
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    this.loadNewToken(() => this.loadGet(endpoint, loadFunction, errorFunction));
                    return;
                } else {
                    errorFunction(JSON.stringify(error.response.data));
                }
            } else {
                errorFunction(JSON.stringify(error));
            }
        })
    }

    loadPublicGet = (endpoint, loadFunction, errorFunction) => {
        axios({
            url: properties.api_public_host + endpoint,
            method: "GET",
            headers: {
                'X-Api-Key': properties.basic2
            }
        }).then((response) => {
            if (response.data) {
                try {
                    loadFunction(response.data);
                } catch (error) {
                    errorFunction("Application Error: " + error);
                }
            } else {
                errorFunction(JSON.stringify(response));
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    //this.loadNewToken(() => this.loadGet(endpoint, loadFunction, errorFunction));
                    console.log("Problem");
                    return;
                } else {
                    errorFunction(JSON.stringify(error.response.data));
                }
            } else {
                errorFunction(JSON.stringify(error));
            }
        })
    }

    loadPost = (endpoint, data, loadFunction, errorFunction) => {
        axios({
            url: properties.api_host + endpoint,
            method: "POST",
            headers: {
                'Authorization': this.props.token
            },
            data: data
        }).then((response) => {
            if (response.data) {
                try {
                    loadFunction(response.data);
                } catch (error) {
                    errorFunction("Application Error: " + error);
                }
            } else {
                errorFunction(JSON.stringify(response));
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    this.loadNewToken(() => this.loadPost(endpoint, data, loadFunction, errorFunction))
                    return;
                } else {
                    errorFunction(JSON.stringify(error.response.data));
                }
            } else {
                errorFunction(JSON.stringify(error));
            }
        })
    }

    loadPut = (endpoint, data, loadFunction, errorFunction) => {
        axios({
            url: properties.api_host + endpoint,
            method: "PUT",
            headers: {
                'Authorization': this.props.token
            },
            data: data
        }).then((response) => {
            if (response.data) {
                try {
                    loadFunction(response.data);
                } catch (error) {
                    errorFunction("Application Error: " + error);
                }
            } else {
                errorFunction(JSON.stringify(response));
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    this.loadNewToken(() => this.loadPut(endpoint, data, loadFunction, errorFunction))
                    return;
                } else {
                    errorFunction(JSON.stringify(error.response.data));
                }
            } else {
                errorFunction(JSON.stringify(error));
            }
        })
    }

    loadDelete = (endpoint, loadFunction, errorFunction) => {
        axios({
            url: properties.api_host + endpoint,
            method: "DELETE",
            headers: {
                'Authorization': this.props.token
            }
        }).then((response) => {
            if (response.status === 200) {
                try {
                    loadFunction(response);
                } catch (error) {
                    errorFunction("Application Error: " + error);
                }
            } else {
                errorFunction(JSON.stringify(response));
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    this.loadNewToken(() => this.loadDelete(endpoint, loadFunction, errorFunction))
                    return;
                } else {
                    errorFunction(JSON.stringify(error.response.data));
                }
            } else {
                errorFunction(JSON.stringify(error));
            }
        })
    }

    loadNewToken = (loadFunction) => {
        this.functionLoadAfterToken(loadFunction)
        this.setError(false)
        axios({
            url: properties.url_token_r,
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': properties.basic,
            },
            params: {
                refresh_token: this.props.refresh
            },
            data: ''
        }).then((response) => {
            if (response.data && response.data.access_token) {
                let data = response.data
                this.props.saveToken(data.access_token);
                loadFunction();
            } else {
                this.setError(JSON.stringify(response));
                this.setState({
                    user: false
                });
            }
        }).catch((onerror) => {
            if (onerror.response) {
                this.setState({
                    user: false
                });
            } else {
                this.setError("Error no Identificado");
            }
        })
    }

    loadGetBucket = (endpoint, loadFunction, errorFunction) => {
        axios({
            url: properties.bucket_host + endpoint,
            method: "GET",
            responseType: 'arraybuffer'
        }).then((response) => {
            if (response.data) {
                try {
                    let data = Buffer.from(response.data, 'binary').toString('base64');
                    loadFunction("data:image/png;base64," + data);
                } catch (error) {
                    errorFunction("Application Error: " + error);
                }
            } else {
                errorFunction(JSON.stringify(response));
            }
        }).catch((error) => {
            if (error.response) {
                errorFunction(JSON.stringify(error.response.data))
            } else {
                errorFunction(JSON.stringify(error))
            }
        })
    }

    setError = (error) => {
        this.setState({error})
    }

    functionLoadAfterToken = (loadFunction) => {
        this.setState({functionToken: loadFunction})
    }

    setOrder = (order) => {
        this.setState({
            order: order
        });
        if(order){
            this.props.setOrder(order);
        } else {
            this.props.clearOrder();
        }
    }

    calculateNumberOfProducts = () => {
        let order = this.state.order;
        if (order && order.products) {
            return order.products.length
        }
        return 0

    }

    initSearchEvent = (searchText) => {
        let searchTextClean = searchText.replace(/[ \t]*<h[1-6]{1}.*>(.*)<\/h[1-6]{1}>[\n\r]/ig, "")
        this.setState({
            ready: false,
            searchText: searchTextClean
        });
        if (searchTextClean) {
            this.loadPublicGet("/products/"+(this.state.user && this.state.user['custom:client_id']? this.state.user['custom:client_id']:"anonymous")+"?q=" +
                searchTextClean.toUpperCase(), this.loadContent, this.setError
            )
        }
    }

    loadContent = (products) => {
        products.sort((a, b) => {
            return a.code - b.code
        });
        this.setState({
            ready: true,
            products
        });
    }

}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        user: state.user,
        order: state.order,
        refresh: state.refresh,
        client: state.client,
    }
}

const mapDispatchToProps = {
    setOrder,
    saveToken,
    saveRefreshToken,
    setUser,
    setClient,
    clearToken,
    clearRefreshToken,
    clearUser,
    clearOrder,
    clearClient,
}

export default connect(mapStateToProps, mapDispatchToProps)(Portal);