function Privacy(props) {
    return(
        <div>
            <h2>Nuestra Política de Privacidad</h2>
            <hr/>
            <p>
                En cumplimiento a lo estipulado en la ley 1581 de 2012 y a lo previsto en el numeral 3 y el
                parágrafo del artículo 10 del Decreto 1377, con el objetivo de informar a todas las personas cuyos
                datos personales se encuentran en nuestras bases de datos, y teniendo en cuenta la imposibilidad de
                solicitar autorización en forma individual, <b>Líder Camargo SAS</b> informa que hace uso del
                mecanismo alternativo establecido en el citado numeral y manifiesta que los datos personales
                incluidos en nuestras bases de datos se han recompilado para el desarrollo de diversos procedimientos
                relacionados directamente con su objetivo social.
            </p>
            <p>
                El uso y manejo de los mismos, se efectúa bajo estrictos estándares de responsabilidad, dentro de
                los cuales está el respeto al debido proceso y a la protección de la información.
            </p>
            <p>
                Los datos registrados en nuestras bases de datos son, entre otros: reseñas públicas, tales como
                dirección, teléfono, direcciones de correos electrónicos. En todo caso, en cualquier momento el
                titular de la información podrá revocar su consentimiento y ejercer su derecho a la supresión de
                datos personales consagrado en la Ley 1581 de 2012.
            </p>
            <p>
                Si usted desea consultar información o solicitar la supresión de sus datos personales de nuestras
                bases de datos, agradecemos se comunique dentro de los 30 días siguientes a la publicación del
                presente aviso a la dirección Carrera 12 A n. 52 – 69 sur en Bogotá o a los correos electrónicos
                lidercamargosas@hotmail.com. Si decide no hacerlo, vencidos el referido plazo, consideramos
                autorizado al titular de información en cualquier tiempo de consultar, conocer, actualizar
                sus datos personales, o solicitar su supresión o rectificación.
            </p>
        </div>
    )
}
export default Privacy;

