import Home from "./views/Home";
import CarOrder from "./views/CarOrder";
import Checkout from "./views/checkout/Checkout";
import SearchResults from "./views/SearchResults";
import Order from "./views/Order";
import MyOrders from "./views/MyOrders";
import Profile from "./views/Profile";
import Privacy from "./views/Privacy";

let routes = [
    {
        path: "/home",
        name: "Home Store",
        layout: "/store",
        component: Home,
    },
    {
        path: "/order",
        name: "Card Order",
        layout: "/store",
        component: CarOrder,
    },
    {
        path: "/checkout",
        name: "Order Checkout",
        layout: "/store",
        component: Checkout,
    },
    {
        path: "/search",
        name: "Search",
        layout: "/store",
        component: SearchResults,
    },
    {
        path: "/orders/:orderId",
        name: "Orden",
        layout: "/store",
        component: Order,
    },
    {
        path: "/orders",
        name: "Orden",
        layout: "/store",
        component: MyOrders,
    },
    {
        path: "/me",
        name: "Me",
        layout: "/store",
        component: Profile,
    },
    {
        path: "/privacy",
        name: "Privacy",
        layout: "/store",
        component: Privacy,
    }
]
export default routes;