import {Badge, Button, Col, Container, Form, FormControl, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {NavLink, useHistory} from "react-router-dom";
import {FiShoppingCart} from "react-icons/fi";
import {AiOutlineLogout} from "react-icons/ai";
import logo from "../assets/img/logoLidercam.png"
import properties from "../properties";
import {useState} from "react";

function IndexNavbar({current, user, setSearchTextParent}) {
    const [searchText, setSearchText] = useState('')

    let history = useHistory();
    let userName = "Invitado";
    let userEmail = "Mail";
    if (user) {
        userName = user['custom:name'];
        userEmail = user.email;
    }


    return (
        <Navbar variant={"light"} expand="lg" className={"navbar-lider"}>
            <Container>
                <Navbar.Brand>
                    <NavLink to={"/store/home"}>
                        <img src={logo} alt={"LIDER CAMARGO LOGO"} className={"logo"}/>
                    </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Col>
                        <Form
                            onSubmit={
                                (event) => {
                                    event.preventDefault();
                                    if (searchText) {
                                        setSearchTextParent(searchText)
                                        history.push("/store/search?q=" + searchText);
                                    }
                                }
                            }>
                            <FormControl
                                type="search"
                                name="q"
                                placeholder="Buscar"
                                className="me-2"
                                aria-label="Search"
                                value={searchText}
                                onChange={(event) => setSearchText(event.target.value)}
                            />
                        </Form>
                    </Col>
                    <Nav>
                        <NavLink to={"/store/order"} className={"nav-link"}>
                            <Button size={"sm"} variant={"secondary"}>
                                <i><FiShoppingCart/></i> <Badge bg="warning">{current}</Badge>
                                <span className="visually-hidden">Productos en el carrito</span>
                            </Button>
                        </NavLink>
                        {user ? (
                            <>
                                <NavLink to={"/store/orders"} className={"nav-link"}>Mis Pedidos</NavLink>
                                <NavDropdown title={userName ? userName : userEmail}
                                             id="collasible-nav-dropdown"
                                >
                                    <NavLink to={"/store/me"} className={"dropdown-item"}>Mi Perfil</NavLink>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href={properties.url_logout}><i><AiOutlineLogout/></i> Salir
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </>
                        ) : (
                            <>
                                <Button variant="link" href={properties.url_login}>
                                    Iniciar Sesión
                                </Button>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default IndexNavbar;