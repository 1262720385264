import React, {Component} from "react";
import {clearRefreshToken, clearToken, saveRefreshToken, saveToken, setUser} from "../initializers/actions";
import {connect} from "react-redux";
import {Card, Container} from "react-bootstrap";
import axios from "axios";
import {Redirect} from "react-router-dom";
import '../assets/css/App.css';
import properties from "../properties";
import logo from '../logo.svg';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLogin: false,
            isError: false,
            error: ""
        }
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search)
        this.loadingToken(params.get("code"));
    }

    render() {
        if (this.state.isLogin) {
            return (
                <Redirect
                    to={"/portal/home"}
                />
            )
        } else {
            return (
                <div //style={{backgroundColor: "#909090"}}
                    >
                    {this.state.isError ? (
                        <Container>
                            <Card className={"text-center"} border={"danger"}>
                                <Card.Header>
                                    Error
                                </Card.Header>
                                <Card.Body>
                                    <h4><b>Algo salio mal </b>: {this.state.error}</h4>
                                    <Card.Link
                                        href={properties.url_login}
                                    >
                                        Volver a iniciar sesión aqui
                                    </Card.Link>
                                </Card.Body>
                                <Card.Footer></Card.Footer>
                            </Card>
                        </Container>
                    ):(
                        <div className="App">
                            <header className="App-header">
                                <img src={logo} className="App-logo" alt="logo" />
                                <p>
                                    Cargando la tienda Lider Camargo
                                </p>
                            </header>
                        </div>
                    )}
                </div>
            );
        }
    }

    loadingToken = (code) => {
        if (code === "" || code == null) {
            this.setState({
                isError: true,
                error: "No existe el parametro Code"
            })
        } else {
            axios({
                url: properties.url_token,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': properties.basic,
                },
                params: {
                    code
                },
                data: ''
            }).then(
                (response) => {
                    if (response.data) {
                        let data = response.data
                        this.props.saveToken(data.access_token)
                        this.props.saveRefreshToken(data.refresh_token)
                        this.getUserInfo()
                    } else {
                        this.setState({
                            isError: true,
                            error: JSON.stringify(response)
                        })
                    }
                }
            ).catch((error) => {
                if (error.response) {
                    this.setState({
                        isError: true,
                        error: JSON.stringify(error.response.data)
                    });
                } else {
                    this.setState({
                        isError: true,
                        error: "Error no Identificado"
                    })
                }

            })
        }
    }

    getUserInfo = () => {
        axios({
            url: properties.url_user_inf,
            method: "GET",
            headers: {
                'Authorization': "Bearer " + this.props.token
            }
        }).then((response) => {
            if (response.data) {
                this.props.setUser(response.data)
                setTimeout( () => this.setState({
                    isLogin: true
                }), 100);
            } else {
                this.setState({
                    isError: true,
                    error: JSON.stringify(response)
                })
            }
        }).catch((error) => {
            if (error.response) {
                this.setState({
                    isError: true,
                    error: JSON.stringify(error.response.data)
                });
            } else {
                this.setState({
                    isError: true,
                    error: "Error no Identificado"
                })
            }
        })
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        user: state.user,
        refresh: state.refresh
    }
}

const mapDispatchToProps = {
    saveToken,
    saveRefreshToken,
    setUser,
    clearToken,
    clearRefreshToken
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);