import {Component} from "react";
import {Alert, Col, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";

class Profile extends Component{
    render() {
        return(
            <div>
                <h2>Mis datos</h2>
                <hr/>
                <Alert>
                    <Row>
                        <Col sm={6}>
                            <Row>
                                <Col>
                                    <b>Cliente: </b>
                                </Col>
                                <Col>
                                    {this.props.user['custom:name'] ?
                                        this.props.user['custom:name'] + " " + this.props.user['custom:last_name_v2'] :
                                        this.props.client.name
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6}>
                            <Row>
                                <Col>
                                    <b>CC/TI/ID: </b>
                                </Col>
                                <Col>
                                    {this.props.user['custom:identification_v2'] ? this.props.user['custom:identification_v2'] :
                                        this.props.client.identification
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6}>
                            <Row>
                                <Col>
                                    <b>Email: </b>
                                </Col>
                                <Col>
                                    {this.props.user['email']}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6}>
                            <Row>
                                <Col>
                                    <b>NIT(cliente): </b>
                                </Col>
                                <Col>
                                    {this.props.client.identification}
                                </Col>
                            </Row>
                        </Col>
                        {this.props.client.establishment_name ? (
                            <Col sm={6}>
                                <Row>
                                    <Col>
                                        <b>Establecimiento: </b>
                                    </Col>
                                    <Col>
                                        {this.props.client.establishment_name}
                                    </Col>
                                </Row>
                            </Col>
                        ) : null}
                        {this.props.client.department || this.props.client.city ? (
                            <Col sm={6}>
                                <Row>
                                    <Col>
                                        <b>Ciudad: </b>
                                    </Col>
                                    <Col>
                                        {this.props.client.city}/{this.props.client.department}
                                    </Col>
                                </Row>
                            </Col>
                        ) : null}
                        {this.props.client.address ? (
                            <Col sm={6}>
                                <Row>
                                    <Col>
                                        <b>Dirección: </b>
                                    </Col>
                                    <Col>
                                        {this.props.client.address}
                                    </Col>
                                </Row>
                            </Col>
                        ) : null}
                        {this.props.client.phone ? (
                            <Col sm={6}>
                                <Row>
                                    <Col>
                                        <b>Teléfonos: </b>
                                    </Col>
                                    <Col>
                                        {this.props.client.phone}
                                    </Col>
                                </Row>
                            </Col>
                        ) : null}
                    </Row>
                </Alert>
                <Alert variant={"danger"}>
                    <small>*Tus datos no se pueden editar en esta plataforma, para editarlos por favor comunicarse con
                    LIDER CAMARGO SAS o alguno de sus asesores</small>
                    <br/>
                    <p className={"text-end"}>
                        <NavLink to={"/store/privacy"}>
                            Ver Política de Privacidad
                        </NavLink>
                    </p>
                </Alert>
            </div>
        )
    }
}
export default Profile