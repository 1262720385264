import React, {Component} from "react";
import {Container} from "react-bootstrap";
import {NavLink} from "react-router-dom";

class Footer extends Component{
    render() {
        return(
            <div className={"footer"}>
                <Container>
                    <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top ">
                        <ul className="nav col-md-4 ">
                            <li className="nav-item">
                                <NavLink to={"/store/home"}
                                         className={"nav-link px-2 text-muted"}
                                >
                                    Inicio
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"/store/privacy"} className="nav-link px-2 text-muted">
                                    Política de Privacidad
                                </NavLink>
                            </li>
                        </ul>
                        <a href="https://lidercamargo.com"
                           className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                            LÍDER CAMARGO SAS
                        </a>
                        <p className="col-md-4 mb-0 text-muted justify-content-end text-end">&copy; {1900 + new Date().getYear()}{" "} andiazher, Inc</p>
                    </footer>
                </Container>
            </div>

        )
    }
}
export default Footer;