import {createStore, combineReducers, compose} from 'redux';
import persistState from 'redux-localstorage';

function tokenReducer(state = '', action) {
    switch (action.type) {
        case 'SET_TOKEN':
            return action.token;
        case 'CLEAR_TOKEN':
            return '';
        default:
            return state;
    }
}

function refreshTokenReducer(state = '', action) {
    switch (action.type) {
        case 'SET_REFRESH_TOKEN':
            return action.refresh;
        case 'CLEAR_REFRESH_TOKEN':
            return '';
        default:
            return state;
    }
}

function userReducer(state = null, action) {
    switch (action.type) {
        case 'LOGGED_IN':
            return action.user;
        case 'SIGN_OUT':
            return null;
        default:
            return state;
    }
}

function orderReducer(state = null, action) {
    switch (action.type) {
        case 'SET_ORDER':
            return action.order;
        case 'CLEAR_ORDER':
            return false;
        default:
            return state;
    }
}

function productsReducer(state = [], action) {
    switch (action.type) {
        case 'SET_PRODUCTS':
            return action.products;
        case 'CLEAR_PRODUCTS':
            return null;
        default:
            return state;
    }
}

function photosReducer(state = null, action) {
    switch (action.type) {
        case 'SET_CLIENT':
            return action.client;
        case 'CLEAR_CLIENT':
            return null;
        default:
            return state;
    }
}

let rootReducers = combineReducers({
    token: tokenReducer,
    refresh: refreshTokenReducer,
    user: userReducer,
    order: orderReducer,
    products: productsReducer,
    client: photosReducer
});

let mainEnhancer = compose(persistState(['token', 'refresh', 'user', 'order', 'products','client']))

export default createStore(rootReducers, {}, mainEnhancer)