import {Alert, Modal, Spinner} from "react-bootstrap";

function ModalOrder(props) {
 return(
     <Modal centered {...props} backdrop="static" keyboard={false} size={"sm"}>
         <Modal.Body className={"text-center"}>
             {props.error?(
                 <Alert variant={"danger"}>
                     {props.error}
                 </Alert>
             ):(
                 <Spinner animation={"border"}/>
             )}
         </Modal.Body>
     </Modal>
 )
}
export default ModalOrder;