import {Component} from "react";
import {Container} from "react-bootstrap";
import {Switch} from "react-router-dom";

class MainPanel extends Component{
    render() {
        return (
            <Container>
                <Switch>
                    {this.props.route}
                </Switch>
            </Container>
        )
    }
}
export default MainPanel;