import ProductCard from "./ProductCard";
import {Button, Card, Col, FormControl, InputGroup, Row} from "react-bootstrap";
import {FiMinus, FiPlus} from "react-icons/fi";
import {AiFillDelete} from "react-icons/ai";

class ProductCardSimple extends ProductCard {

    render() {
        let product = this.props.product
        return (
            <Card className={"shadow mb-3 bg-white rounded"}>
                <Card.Body>
                    <Row>
                        <Col xs={12} sm={"2"} md={1} lg={1} className={"text-center"}>
                            <Card.Img src={this.state.image} style={{height: "2.3rem", width: "2.3rem"}}/>
                        </Col>
                        <Col sm={10} md={7} lg={5} xl={6}>
                            <Row>
                                <Card.Text>
                                    {product.name}
                                </Card.Text>
                            </Row>
                            <Row>
                                <small className="text-muted"
                                       style={{top: "2.2rem", position: "absolute"}}
                                >
                                    Ref: <b>{product.code}</b>
                                </small>
                            </Row>
                        </Col>
                        <Col sm={5} md={4} lg={3} xl={2}>
                            <InputGroup>
                                {this.state.amount === 1 || this.state.amount === "1" ? (
                                    <Button variant={"danger"}
                                            onClick={() => {
                                                this.validateValue(0);
                                            }}
                                    >
                                        <i><AiFillDelete/></i>
                                    </Button>
                                ) : (
                                    <Button style={{backgroundColor: '#c0ce00', borderColor: '#c0ce00', color: "black"}}
                                            onClick={() => this.validateValue(parseInt(product.amount) - 1)}
                                    >
                                        <i><FiMinus/></i>
                                    </Button>
                                )}
                                <FormControl
                                    type={"number"}
                                    className={"text-center"}
                                    value={product.amount}
                                    onChange={(event) => {
                                        this.validateValue(event.target.value);
                                    }}
                                />
                                <Button style={{backgroundColor: '#c0ce00', borderColor: '#c0ce00', color: "black"}}
                                        onClick={() => this.validateValue(parseInt(product.amount) + 1)}
                                >
                                    <i><FiPlus/></i>
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col style={{alignItems: "center", display: "flex", justifyContent: "right"}}
                             sm={3} md={6} lg={1}
                        >
                            ${new Intl.NumberFormat('de-DE').format(product.value)}
                        </Col>
                        <Col style={{alignItems: "center", display: "flex", justifyContent: "right"}}
                             sm={3} md={6} lg={2}
                        >
                            <b>
                                ${new Intl.NumberFormat('de-DE').format(product.total)}
                            </b>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }
}

export default ProductCardSimple;
