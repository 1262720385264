import {Component} from "react";
import {Button, Card, Col, FormControl, InputGroup, Row} from "react-bootstrap";
import BackImage from "../assets/img/logo512.png";
import {FiMinus, FiPlus} from "react-icons/fi";
import {AiFillDelete} from "react-icons/ai";

class ProductCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: BackImage,
            is_added: false,
            amount: 0,
        };
    }

    componentDidMount() {
        this.calculateAmountSaved();
        this.downloadImage();
    }

    render() {
        const {product} = this.props;
        return (
            <Card className="shadow-lg mb-4 bg-white rounded">
                {/* Imagen cuadrada en la parte superior */}
                <div className="text-center">
                    <Card.Img
                        src={this.state.image}
                        style={{
                            height: "200px",
                            width: "200px",
                            objectFit: "cover",
                            margin: "auto",
                        }}
                        className="mt-3"
                    />
                </div>
                <Card.Body>
                    {/* Detalles del producto */}
                    <Card.Title
                        className="text-center overflow-hidden"
                        style={{
                            maxHeight: "3rem",
                            height: "3rem",
                            fontSize: "1.1rem",
                        }}
                    >
                        {product.name}
                    </Card.Title>
                    <Row className="mb-2">
                        <Col>
                            <small className="text-muted">Ref: <b>{product.code}</b></small>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <small className="text-muted">P/u:</small>
                        </Col>
                        <Col style={{textAlign: "right"}}>
                            <b>${new Intl.NumberFormat('de-DE').format(this.getDefaultValue(product))}</b>
                        </Col>
                    </Row>
                    {/* Controles */}
                    <Row className="text-center">
                        {this.state.is_added ? (
                            <InputGroup>
                                {this.state.amount === 1 || this.state.amount === "1" ? (
                                    <Button
                                        variant="danger"
                                        onClick={() => this.validateValue(0)}
                                    >
                                        <i><AiFillDelete/></i>
                                    </Button>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: "#c0ce00",
                                            borderColor: "#c0ce00",
                                            color: "black",
                                        }}
                                        onClick={() => this.validateValue(parseInt(this.state.amount) - 1)}
                                    >
                                        <i><FiMinus/></i>
                                    </Button>
                                )}
                                <FormControl
                                    type="number"
                                    className="text-center"
                                    value={this.state.amount}
                                    onChange={(event) => {
                                        this.validateValue(event.target.value);
                                    }}
                                />
                                <Button
                                    style={{
                                        backgroundColor: "#c0ce00",
                                        borderColor: "#c0ce00",
                                        color: "black",
                                    }}
                                    onClick={() => this.validateValue(parseInt(this.state.amount) + 1)}
                                >
                                    <i><FiPlus/></i>
                                </Button>
                            </InputGroup>
                        ) : (
                            <Button
                                style={{
                                    backgroundColor: "#c0ce00",
                                    borderColor: "#c0ce00",
                                    color: "black",
                                }}
                                onClick={() => this.validateValue(1)}
                            >
                                Agregar
                            </Button>
                        )}
                    </Row>
                </Card.Body>
            </Card>
        );
    }

    getDefaultValue = (product) => {
        if (product.sales_list && product.sales_list.length > 0) {
            if (product.sales_list[product.sales_list_default]) {
                if (product.iva) {
                    return parseInt(product.sales_list[product.sales_list_default].value * (1 + (product.iva / 100)));
                }
                return product.sales_list[product.sales_list_default].value;
            }
            return product.sales_list[0].value;
        }
        return 0;
    };

    validateValue = (value) => {
        if (value > 0) {
            this.setState({
                amount: parseInt(value),
                is_added: true,
            });
            this.addProductToOrder(parseInt(value));
        } else {
            if (value === "") {
                this.setState({
                    amount: "",
                    is_added: true,
                });
            } else {
                this.setState({
                    amount: 0,
                    is_added: false,
                });
                this.removeProductInOrder();
            }
        }
    };

    calculateAmountSaved = () => {
        const {order} = this.props;
        if (order && order.products && order.products.length > 0) {
            for (let product of order.products) {
                if (product.code === this.props.product.code) {
                    this.setState({
                        amount: product.amount,
                        is_added: true,
                    });
                }
            }
        }
    };

    addProductToOrder(amount) {
        const {order, product, setOrder} = this.props;
        if (!order || !order.products || order.products.length === 0) {
            order['products'] = [];
        }
        let products = order.products;
        let is_found = false;
        for (let item of products) {
            if (item.code === product.code) {
                is_found = true;
                item.amount = amount;
                item.total = amount * item.value;
            }
        }
        if (!is_found) {
            let default_value = this.getDefaultValue(product);
            products.push({
                id: products.length,
                code: product.id,
                amount: amount,
                name: product.name,
                value: default_value,
                iva: product.iva,
                weight: product.weight,
                option: true,
                total: default_value * amount,
                url_image: product.url_image,
            });
        }
        setOrder(order);
    }

    removeProductInOrder() {
        const {order, product, setOrder} = this.props;
        if (order && order.products && order.products.length > 0) {
            let newProducts = [];
            for (let item of order.products) {
                if (item.code !== product.code) {
                    newProducts.push(item);
                }
            }
            order.products = newProducts;
            setOrder(order);
        }
    }

    downloadImage = () => {
        const {product, loadGetBucket} = this.props;
        if (product['url_image']) {
            loadGetBucket(`/images/${product['url_image']}`, this.loadImage, this.loadErrorBucket);
        }
    };

    loadImage = (image) => {
        this.setState({image});
    };

    loadErrorBucket = (error) => {
        console.log(error);
    };
}

export default ProductCard;
