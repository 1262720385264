import {useEffect, useState} from "react";
import {Alert, Spinner, Table} from "react-bootstrap";
import {NavLink} from "react-router-dom";

function getStatus(status) {
    if(status==="active"){
        return "ABIERTA"
    }
    if (status==="process"){
        return "ALISTANDO"
    }
    if (status==="closed"){
        return "ENTREGADA"
    }
    return "N/A";
}

function MyOrders(props){
    let [orders, setOrders] = useState(null);
    let [error, setError] = useState(null);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.client && props.client.id && !orders) {
            props.loadGet("/orders/" + props.client.id,
                (orders) => {
                    setOrders(orders);
                    setLoading(false);
                },
                (error) => {
                    setError(error);
                    setLoading(false);
                });
        }
        if(!props.client){
            setError("No tienes la tienda completamente configurada, por favor contactar a lidercamargo");
            setLoading(false);
        }
    }, [orders, props]);

    if(loading){
        return (
            <div className={"text-center"}>
                <br/>
                <Spinner animation={"border"} />
            </div>
        )
    }

    if(error){
        return (
            <div>
                <NavLink to={"/store/home"}>
                    Ir a Inicio
                </NavLink>
                <hr/>
                <Alert variant={"danger"}>
                    Algo salio mal:
                    <hr/>
                    {error}
                </Alert>
            </div>
        );
    }

    if(!orders ||orders.length === 0){
        return (
            <div>
                <h2>Mis Ordenes</h2>
                <hr/>
                <Alert>
                    :( No tienes pedidos
                    <hr/>
                    <NavLink to={"/store/home"}>
                        Crea tu primera orden agregando productos al carrito aquí.
                    </NavLink>
                </Alert>
            </div>
        )
    }

    return(
        <div>
            <h2>Mis Ordenes</h2>
            <hr/>
            <Table size={"sm"} hover striped bordered responsive>
                <thead>
                <tr className={"text-center"}>
                    <th>ID</th>
                    <th>FECHA</th>
                    <th>VALOR T</th>
                    <th>F. PAGO</th>
                    <th>ESTADO</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {orders?.map( (order) => {
                    return(
                        <tr>
                            <td className={"text-center"}>
                                <NavLink to={"/store/orders/"+order.id}>
                                    {order.id}
                                </NavLink>
                            </td>
                            <td className={"text-center"}>
                                {new Date(order.date*1000).toLocaleDateString("es-US")}
                            </td>
                            <td className={"text-end"}>
                                ${new Intl.NumberFormat('de-DE').format(order.total)}
                            </td>
                            <td className={"text-center"}>
                                {order.payment_method}
                            </td>
                            <td className={"text-center"}>
                                {getStatus(order.status)}
                            </td>
                            <td className={"text-center"}>
                                <NavLink to={"/store/orders/"+order.id}>
                                    Ver
                                </NavLink>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </div>
    )
}
export default MyOrders;